import { ModalBase } from "../modal_base";
import { TachoMissingMileage_edit_comment } from "../../list/tco_mis_mil";

export class TcoMisMilCommentEdit extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_edit');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        window.dispatchEvent(new Event('RebuildAutocompletes'));
        TachoMissingMileage_edit_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment_edit').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment_edit').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment_edit').removeClass('error');
            TachoMissingMileage_update_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
            this.hide();
        });
    }
}
