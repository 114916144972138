import { ModalBase } from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";

export class UnlinkSimModal extends ModalBase {
    constructor() {
        super('unlink_sim_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        const asset = $modalTriggerBtn.data('veh');
        const operator = $modalTriggerBtn.data('opr');
        const currentAssetEl = this.MODAL_CONTAINER_EL.find('#current-asset');
        const currentCompanyEl = this.MODAL_CONTAINER_EL.find('#current-company');
        this.MODAL_CONTAINER_EL.find('#current-iccid b').text($modalTriggerBtn.data('iccid'));
        if(asset && asset.length > 0) {
            currentAssetEl.find('b').text(asset);
        } else {
            currentAssetEl.hide();
        }

        if(operator && operator.length > 0) {
            currentCompanyEl.find('b').text(operator);
        } else {
            currentCompanyEl.hide();
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            AjaxSync({
                url: `${app.CACHE.URL_AJAX}unlink_sim`,
                method: 'POST',
                data: {
                    iccid: $modalTriggerBtn.data('iccid'),
                }
            }, {
                done: (res) => this.ajaxSuccess(res, $modalTriggerBtn),
            });
        });
    }

    ajaxSuccess(res, $modalTriggerBtn) {
        if(res.status === 'success') {
            $modalTriggerBtn.hide();
            $modalTriggerBtn.closest('tr').find('.col_operator_name').html('-');
        }
    }
}