import { ModalBase } from "../modal_base";
import {AjaxPromise} from "../../shared/ajax_utilities";
import {displayErrors} from "../../shared/common";

export class ListResolveModal extends ModalBase {
    constructor() {
        super('list_resolve_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if(!additionalData.resolveType) {
            console.warn('No resolve type specified.');
            return;
        }

        if(app.URI[2] === 'veh_def' && $modalTriggerBtn.hasClass('btn-resolve')) {
            remodal_open_check_monitoring(this.MODAL_CONTAINER_EL, $modalTriggerBtn);
        }
        this.MODAL_CONTAINER_EL.removeClass('btn-pending').removeClass('btn-monitoring').removeClass('btn-resolve');

        switch(additionalData.resolveType) {
            case 'pending':
                this.MODAL_CONTAINER_EL.addClass('btn-pending');
                break;
            case 'monitoring':
                this.MODAL_CONTAINER_EL.addClass('btn-monitoring');
                break;
            case 'unresolve':
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
                break;
            case 'resolve':
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
                break;
        }

        if($modalTriggerBtn.hasClass('btn-resolve') ) {
            if($modalTriggerBtn.hasClass('btn-pending') ) {
                this.MODAL_CONTAINER_EL.addClass('btn-pending');
            } else if($modalTriggerBtn.hasClass('btn-monitoring') ) {
                this.MODAL_CONTAINER_EL.addClass('btn-monitoring');
            } else {
                this.MODAL_CONTAINER_EL.addClass('btn-resolve');
            }
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            loading('start', $modalTriggerBtn);

            let $tr = $modalTriggerBtn.parent().parent(),
                $table = $tr.parent(),
                url = `${app.CACHE.URL_AJAX}veh_def/edit/${$modalTriggerBtn.attr('data-id')}`,
                is_resolved = ( $modalTriggerBtn.attr('data-is-resolved') === undefined ) ? '1' : '0',
                $is_monitoring_ajax = this.MODAL_CONTAINER_EL.find('.is_monitoring_ajax'),
                $notesContainer = this.MODAL_CONTAINER_EL.find('#notes_resolved_container'),
                $notes = $notesContainer.find('#notes_resolved'),
                $cost = this.MODAL_CONTAINER_EL.find('#cost');

            // hide by default
            $is_monitoring_ajax.hide();

            if( $modalTriggerBtn.hasClass('btn-pending') ) {
                is_resolved = '2';
            } else if( $modalTriggerBtn.hasClass('btn-monitoring') ) {
                is_resolved = '3';
            }

            var errors = checkErrors($modalTriggerBtn, [$notes]);

            // re-open modal
            if( errors.length > 0 ) {
                loading('end', $modalTriggerBtn);
                return false;
            }

            try {
                const res = await AjaxPromise({
                    'method': 'POST',
                    'url': url,
                    'data': {
                        'tbl': $modalTriggerBtn.attr('data-tbl'),
                        'is_resolved': is_resolved,
                        'notes_resolved': $notes.val(),
                        'operator_id': $modalTriggerBtn.attr('data-operator-id'),
                        'question_id': $modalTriggerBtn.attr('data-question-id'),
                        'vehicle_id': $modalTriggerBtn.attr('data-vehicle-id')
                    }
                });

                if( res.status !== 'success' ) {
                    loading('end', $modalTriggerBtn);
                    displayErrors(res, false, `There was an error please contact ${app.EMAIL}`);
                    return;
                }

                loading('hide', $modalTriggerBtn);

                // remove from dom
                if( is_resolved === '2' || is_resolved === '3' ) {
                    // redirect
                    this.#resolveRedirect(is_resolved, $cost, $modalTriggerBtn);
                    return;
                }

                const that = this;
                $tr.addClass('fadeOut').fadeOut('slow', 'swing', () => {

                    $(this).addClass('hide');

                    // check any more rows
                    if( $table.find('tr').not('.view,.hide').length === 0 ) {
                        if( app.URI[1] === 'list' ) {
                            $table.parent().parent().addClass('fadeOut').fadeOut('slow');
                            // update total
                            updateTableCount();
                        } else {
                            $table.parent().parent().parent().addClass('fadeOut').fadeOut('slow');
                        }
                    }

                    // redirect
                    that.#resolveRedirect(is_resolved, $cost, $modalTriggerBtn);
                });
            } catch(err) {
                loading('end', $modalTriggerBtn);
                console.log(err);
                displayErrors(err, {}, `There was an error please contact ${app.EMAIL}`);
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }

    #resolveRedirect(is_resovled, $cost, $btn) {
        var action = {
            '1': 'Resolved',
            '2': 'Pending',
            '3': 'Monitoring'
        };

        // reload page
        if( $cost.is(':checked' ) ){

            alert(`You have successfully set this defect to ${action[is_resovled]}

-> Redirecting you to add a Cost for this ${app.TBL.veh.i}`);

            setTimeout(function(){
                window.location = `${app.CACHE.URL_ADMIN}cos/false/veh:${$btn.attr('data-vehicle-id')}/${$btn.attr('data-tbl')}:${$btn.attr('data-id')}`;
            }, 250);
        } else {
            location.reload();
        }
    }
}
