import {ModalBase} from "../modal_base";
import {AjaxSync} from "../../shared/ajax_utilities";

export class AssignSimDetailsModal extends ModalBase {
    SIMS = [];
    OPERATOR_ID;

    constructor() {
        super('assign_sim_details');

        this.setupInitialEvents();
    }

    async setupModalView($modalTriggerBtn, additionalData) {
        if(!additionalData || !additionalData.sims || additionalData.sims.length === 0) {
            await this.hide('No sim cards selected.');
            return;
        }

        const filteredSims = additionalData.sims.filter((sim) => sim && sim.trim().length > 0);
        if(filteredSims.length === 0) {
            await this.hide('No sim cards selected.');
            return;
        }

        window.dispatchEvent(new Event('RebuildAutocompletes'));
        this.SIMS = additionalData.sims;
        additionalData.sims.forEach((sim) => {
            this.MODAL_CONTAINER_EL.find('ul').append(`<li>${sim}</li>`);
        });
    }

    setupModalEvents($modalTriggerBtn, additionalData) {

        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if(!this.OPERATOR_ID || this.OPERATOR_ID.length === 0) {
                this.MODAL_CONTAINER_EL.find('#operator_name').addClass('required').addClass('error')
                return;
            }

            AjaxSync({
                url: `${app.CACHE.URL_AJAX}sim_details/edit`,
                method: 'POST',
                data: {
                    operator_id: this.OPERATOR_ID,
                    sims:        this.SIMS,
                    date_start:  this.MODAL_CONTAINER_EL.find('#date_start').val(),
                }
            }, {
                done: (res) => {

                    if(res.status !== 'success') {
                        console.warn('AJAX Request Error', res);
                        return;
                    }

                    window.location.reload();
                },
                error: (err) => {
                    console.warn('AJAX Request Error', err);
                }
            });
        });

        window.addEventListener('AutoCompleteSelect', (e) => {
            if(e.detail.id === 'opr') {
                this.OPERATOR_ID = e.detail.data.value;
            }
        });

        window.addEventListener('AutoCompleteClear', (_e) => {
            this.OPERATOR_ID = undefined;
        });
    }

    setupInitialEvents() {
        this.MODAL_CONTAINER_EL.find('.datepicker').datepicker({
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            dateFormat: 'dd-mm-yy',
        });
    }
}