import { ListSettingsModal } from "./list/list_settings_modal";
import { ListResolveModal } from "./list/list_resolve_modal";
import { ListSignOffModal } from "./list/list_signoff_modal";
import { ListDeleteModal } from "./list/list_delete_modal";
import { TcoMisMilCommentAdd } from "./tacho/tco_mis_mil_comment_add";
import { TcoMisMilCommentEdit } from "./tacho/tco_mis_mil_comment_edit";
import { TcoMisMilCommentDelete } from "./tacho/tco_mis_mil_comment_delete";
import { MsgInboxModal } from "./msg_inbox";
import { TcoMisMilCommentAddBulkNonePaginated } from "./tacho/tco_mis_mil_comment_add_bulk_none_paginated";
import { AssignSimDetailsModal } from "./list/assign_sim_details_modal";
import { DriverCheckManageModal } from "./drivercheck_manage_modal";
import { UnlinkSimModal } from "./tacho/unlink_sim_modal";
import { AddIntegrationModal } from "./tacho/add_integration_modal";
import { BulkAssignSimModal } from "./tacho/bulk_assign_sim_modal";
import { SimManagementModal } from "./tacho/sim_management_modal";
import { ExtendSimModal } from "./tacho/extend_sim_modal";
import { AssignSimVehModal}  from "./tacho/assign_sim_veh_modal";
import { OperatorSupplierListDeleteModal } from "./list/operator_supplier_list_delete_modal";
import { OperatorSupplierListUpdateModal } from "./list/operator_supplier_list_update_modal";
import { RegenAutoDownloadTokenModal } from "./tacho/regen_auto_download_token";
import { ListAssignModal } from "./list/list_assign_modals";
import { ListFilterSaveModal } from "./list/list_filter_save_modal";
import { ListFilterDeleteModal } from "./list/list_filter_delete_modal";
import { ListConfirmModal } from "./list/list_confirm_modal";
import { ListCopyModal } from "./list/list_copy_modal";
import { ListEmailDefectModal } from "./list/list_email_defect_modal";

// TODO: need for the ModalConfirm event in the list.js
window.addEventListener('ModalCoreSetup', (e) => {
    if(app.OPTIONS.delete) {
        new ListConfirmModal('list_hide_modal');
        new ListConfirmModal('list_unlink_modal');
        new ListDeleteModal();
    }

    if(app.OPTIONS.new_list_structure) {
        new ListSettingsModal();
    }

    if(app.URI.length >= 3) {
        switch (app.URI[2]) {
            case 'veh_def':
            case 'mai_ans':
            case 'veh_def_ajax':
            case 'mai_ans_ajax':
                new ListEmailDefectModal();
                new ListResolveModal();
                break;
            case 'mai':
            case 'mai_ajax':
                if(parseInt(app.CACHE.USER.user_type_id) >= parseInt(app.CACHE.USER_TYPES.OPADMIN))
                    new ListSignOffModal();
                break;
            case 'rep_che':
            case 'rep_che_ajax':
                new ListAssignModal(true);
                new ListAssignModal(false);
                break;
            case 'tco_mis_mil':
            case 'tco_mis_mil_ajax':
                new TcoMisMilCommentAddBulkNonePaginated();
                new TcoMisMilCommentAdd();
                new TcoMisMilCommentEdit();
                new TcoMisMilCommentDelete();
                break;
            case 'msg_inbox':
                new MsgInboxModal();
                break;
            case 'sim_details':
                new AssignSimDetailsModal();
                new UnlinkSimModal();
                new BulkAssignSimModal();
                new SimManagementModal();
                new ExtendSimModal();
                break;
        }
    }

    if(app.OPTIONS.sim_management) {
        new UnlinkSimModal();
        new SimManagementModal();
        new ExtendSimModal();
        new AssignSimVehModal();
    }

    if(app.OPTIONS.company_settings) {
        new AddIntegrationModal();
    }

    if (app.OPTIONS.operator_supplier_list) {
        new OperatorSupplierListDeleteModal();
        new OperatorSupplierListUpdateModal();
    }

    if(app.OPTIONS.regenerate_tacho_token) {
        new RegenAutoDownloadTokenModal();
    }

    if(app.OPTIONS.drivercheck_manage) {
        new DriverCheckManageModal();
    }

    if(app.OPTIONS.saved_filters) {
        new ListFilterDeleteModal();
        new ListFilterSaveModal();
    }

    if(app.OPTIONS.copy || app.OPTIONS.modal_copy) {
        new ListCopyModal();
    }
});