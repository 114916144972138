import { ModalBase } from "../modal_base";

export class RegenAutoDownloadTokenModal extends ModalBase {
    constructor() {
        super('regen_auto_download_token');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const el = $(e.currentTarget);
            const modalEl = $(`#${this.MODAL_ID}`);
            if(modalEl.data('page') === 1) {
                modalEl.data('page', 2).attr('data-page', 2);
                return;
            }

            if(app.CACHE.USER.user_type_id !== app.CACHE.USER_TYPES.CHECKPRO) {
                $modalTriggerBtn.remove();
                app.MODAL_CORE.closeModal(e.modalId);
                resolve(false);
                return;
            }

            const $targetUrl = app.DOM.form.find('#input-copy-tacho-url');
            const $targetToken = app.DOM.form.find('#input-copy-tacho-token');
            const req = $.ajax({
                url: `${app.CACHE.URL_AJAX}tacho_remote_download`,
                method: 'POST',
                data: {
                    method: 'regenerate',
                    operator_id: app.URI[2],
                }
            });

            req.done(async (res) => {
                if(res.status !== 'success') {
                    app.TOAST.error('Something went wrong regenerating token please try again later');
                    return;
                }

                $targetUrl.val(`${window.location.origin}/tacho/${res.data.token ?? ''}`);
                $targetToken.val(res.data.token ?? '');
                app.TOAST.success('Company token regenerated successfully');
                modalEl.data('page', 1).attr('data-page', 1);
                await this.hide();
            });

            req.fail((_err) => {
                app.TOAST.error('Something went wrong regenerating token please try again later');
            });
        });
    }
}