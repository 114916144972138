import {ModalBase} from "../modal_base";
import 'jquery-ui';
import { TachoMissingMileage_add_comment, TachoMissingMileage_bulk_comment } from '../../list/tco_mis_mil';

export class TcoMisMilCommentAdd extends ModalBase {
    constructor() {
        super('tco_mis_mil_comment_add');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        window.dispatchEvent(new Event('RebuildAutocompletes'));
        if($modalTriggerBtn.hasClass('btn-comment-bulk-add')) {
            if($modalTriggerBtn.hasClass('button-grey')) {
                alert('Please select a row');
                return false;
            } else if(!app.DOM.table.find('tbody tr.no-comment').length) {
                alert('Cannot bulk add comments, all selected rows already have comments');
                return false;
            }

            TachoMissingMileage_bulk_comment('add', this.MODAL_CONTAINER_EL);
            return;
        }

        TachoMissingMileage_add_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const noteText = this.MODAL_CONTAINER_EL.find('#comment').val();
            if(!noteText || noteText.trim().length === 0) {
                this.MODAL_CONTAINER_EL.find('#comment').addClass('error');
                return;
            }

            this.MODAL_CONTAINER_EL.find('#comment').removeClass('error');
            TachoMissingMileage_store_new_comment($modalTriggerBtn, this.MODAL_CONTAINER_EL);
            $modalTriggerBtn.parent().parent().addClass('fadeOut').fadeOut('slow');
            updateTableCount();

            this.hide();
        });
    }
}
