import { ModalBase } from "../modal_base";
import {AjaxPromise} from "../../shared/ajax_utilities";

export class ListSignOffModal extends ModalBase {
    constructor() {
        super('list_signoff_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if (additionalData.repair) {
            this.MODAL_CONTAINER_EL.find('#signoff-repair').show();
        } else {
            this.MODAL_CONTAINER_EL.find('#signoff-repair').hide();
        }
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            loading('start', $modalTriggerBtn);

            var url = app.CACHE.URL_AJAX + 'mai/edit/' + $modalTriggerBtn.attr('data-id'),
                $notes = this.MODAL_CONTAINER_EL.find('#notes_signoff');


            try {
                const res = await AjaxPromise({
                    'method': 'POST',
                    'url': url,
                    'data': {
                        'tbl': 'mai',
                        'notes_signoff': $notes.val()
                    }
                });

                if( res.status === 'success' ) {

                    window.location.reload();

                } else if( res.status === 'error') {

                    if( !$notes.val() ){
                        $notes.addClass('error');
                    }

                    checkErrorsAjax($modalTriggerBtn, res.errors);
                } else {
                    console.log(res);
                    alert("There was an error, contact administrator");
                }

                loading('end', $modalTriggerBtn);
            } catch(err) {
                loading('end', $modalTriggerBtn);
                console.log(err);
                alert("There was an error, contact administrator");
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}