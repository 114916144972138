import {ModalBase} from "../modal_base";

export class ListFilterDeleteModal extends ModalBase {
    DOM = {};

    constructor() {
        super('list_filter_delete_modal');
    }

    setupDOM($modalTriggerBtn, additionalData) {
        this.DOM.filter = app.DOM.content.find('#filter');
        this.DOM.filter_load_saved_filter = this.DOM.filter.find('#load_saved_filter');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let targetURL = app.CACHE.URL_AJAX + 'saved_filter/delete/' + this.DOM.filter_load_saved_filter.val();

            $.post(targetURL).done(function () {
                // Drop the ID from the current URL and redirect
                app.URI.pop();
                window.location = '/' + app.URI.join('/');
            });
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}