import {ModalBase} from "../modal_base";
import {AjaxPromise} from "../../shared/ajax_utilities";

export class ListConfirmModal extends ModalBase {
    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
                await AjaxPromise({
                    url: $modalTriggerBtn.attr('href'),
                    method: 'POST',
                });

                window.location.reload();
            } catch(err) {
                console.log(err);
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}