import { ModalBase } from "../modal_base";
import {Toast} from "../../shared/toast";
import {AjaxPromise} from "../../shared/ajax_utilities";
import {autocompleteOpts} from "../../shared/common";

export class AddIntegrationModal extends ModalBase {
    constructor() {
        super('add_integration_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        const value = this.MODAL_CONTAINER_EL.find('#integration-selection').val();
        this.toggleExtraButtons(value);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('#integration-selection').unbind('change').on('change', (e) => {
            const value = $(e.currentTarget).val();
            this.MODAL_CONTAINER_EL.find('.integration-settings').attr('data-integration-type', value);
            this.toggleExtraButtons(value);
            app.setupVolvoConnectInputs();
        });

        this.MODAL_CONTAINER_EL.find('.modal__btn-primary.oauth').unbind('click').on('click', (_e) => {
            const subtype = Number.parseInt(this.MODAL_CONTAINER_EL.find('select#integration-selection').val())
            let url;
            switch (subtype) {
                case 1:
                    url = `${app.CACHE.URL_ADMIN}webfleet/request_authorization`;
                    break;
                case 3:
                    url = `${app.CACHE.URL_ADMIN}samsara/request_authorization`;
                    break;
            }
            if(app.CACHE.USER.user_type_id === app.CACHE.USER_TYPES.CHECKPRO) {
                url += `/${app.URI[2]}`;
            }

            this.MODAL_CONTAINER_EL.find('.integration-settings').attr('data-integration-type', '');
            this.MODAL_CONTAINER_EL.find('#integration-selection').val('');
            this.toggleExtraButtons();
            this.saveModalView();
            window.location.href = url;
        });

        this.MODAL_CONTAINER_EL.find('.modal__btn-primary.form').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const integrationVal = this.MODAL_CONTAINER_EL.find('.integration-settings').attr('data-integration-type');
            this.MODAL_CONTAINER_EL.find('#integration-selection').removeClass('error');
            if(!integrationVal || integrationVal.length === 0) {
                this.MODAL_CONTAINER_EL.find('#integration-selection').addClass('error');
                return;
            }
            await this.addIntegrationAjax(integrationVal);
        });
    }

    toggleExtraButtons(value) {
        this.MODAL_CONTAINER_EL.find(`.modal__footer .oauth`).hide();
        this.MODAL_CONTAINER_EL.find('.modal__footer .form').hide();
        const oauth_integrations = this.MODAL_CONTAINER_EL.find(`.modal__content .integration-settings>.oauth`).map(
          (i, el) => Number.parseInt($(el).data('integration-type'))
        ).get()

        if(!value || value.length === 0) {
            return;
        }

        const selectedIntegration = Number.parseInt(this.MODAL_CONTAINER_EL.find('select#integration-selection').val());
        if(oauth_integrations.length && oauth_integrations.includes(selectedIntegration)) {
            this.MODAL_CONTAINER_EL.find('.modal__footer .oauth').show();
        } else {
            this.MODAL_CONTAINER_EL.find('.modal__footer .form').show();
        }
    }

    async onClose(message) {
        app.setupVolvoConnectInputs();
        await super.onClose(message);
    }

    async addIntegrationAjax(integrationVal) {
        try {
            let data = {
                integration: integrationVal,
                action: 'add',
            };

            const currentTab = this.MODAL_CONTAINER_EL.find(`.integration[data-integration-type="${integrationVal}"]`);
            let hasErrors = false;
            currentTab.find('.error').removeClass('error');
            $.each(currentTab.find('input, select, textarea'), (_k, el) => {
                el = $(el);
                if(!el.val() || el.val().length === 0) {
                    hasErrors = true;
                    el.addClass('error');
                    return;
                }

                const name = el.attr('name');
                if(name && name.length > 0) {
                    data[name] = el.val();
                    return;
                }

                const id = el.attr('id');
                if(id && id.length > 0) {
                    data[id] = el.val();
                }
            });

            if(hasErrors) {
                return;
            }

            currentTab.find('.error-label').remove();
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}tacho_integrations`,
                method: 'POST',
                data: data
            });

            if(res.status !== 'success') {
                await this.ajaxErrorHandler(res, integrationVal);
                return;
            }

            app.DOM.content.find('.no-integrations').css('display', 'none');
            switch(integrationVal) {
                case '1':
                    this.webfleetOptions(data);
                    break;
                case '2':
                    this.volvoOptions(data);
                    break;
                case '3':
                    this.samsaraOptions(data);
                    break;
                case '4':
                    this.geotabOptions(data);
                    break;
            }

            app.setupIntegrationDisconnects();
            this.MODAL_CONTAINER_EL.find(`#integration-selection option[value="${integrationVal}"]`).remove();
            this.MODAL_CONTAINER_EL.find('.integration-settings').attr('data-integration-type', '');
            this.toggleExtraButtons();
            this.saveModalView();
            Toast.success('Successfully added new integration.');
            await this.hide();
        } catch(err) {
            console.error(err);
            Toast.error('Something went wrong adding integration please try again later.');
        }
    }

    async ajaxErrorHandler(res = null, integrationVal = null) {
        if(!integrationVal || !res || !res.errors || res.errors.length === 0) {
            Toast.error('Something went wrong adding integration please try again later.');
            return;
        }

        let showsErrors = false;
        res.errors.forEach((error) => {
            const el = this.MODAL_CONTAINER_EL.find(`.integration[data-integration-type="${integrationVal}"]`);
            if(!el || el.length === 0) {
                return;
            }

            const input = el.find(`[id="${error.key}"], [name="${error.key}"]`);
            if(!input || input.length === 0) {
                return;
            }

            showsErrors = true;
            input.addClass('error');
            input.after(`<p class="error-label red">${error.msg}</p>`);
        });

        if(showsErrors) {
            Toast.error('There were issues please check all inputs are correct.');
        } else {
            Toast.error('Something went wrong adding integration please try again later.');
        }
    }

    webfleetOptions(data) {
        let url = `${app.CACHE.URL_ADMIN}webfleet/revoke_authorization`;
        if(app.CACHE.USER.user_type_id === app.CACHE.USER_TYPES.CHECKPRO) {
            url += `/${app.URI[2]}`;
        }

        app.DOM.content.find('.integration-table').append(`
            <tr data-integration-type="1">
                <td>WebFleet</td>
                <td>
                    If you have already connected AssetGo and WebFleet, you can disconnect at any time.<br>
                    <br>
                    We will attempt to collect data every day at 8:00am.<br>
                    <br>
                    To prevent overloading, attempts to reconnect to WebFleet will be blocked until after 8:00am the next day.
                </td>
                <td>N/A</td>
                <td>0</td>
                <td>
                    <a href="${url}">Disconnect</a>
                </td>
            </tr>
        `);
    }

    volvoOptions(data) {
        app.DOM.content.find('.integration-table').append(`
            <tr data-integration-type="2">
                <td>Volvo Connect</td>
                <td>
                    Enter your username and password here to allow AssetGo to pull Tachograph data from your Volvo Connect
                    account.<br>
                    <br>
                    In compatible vehicles, we will be able to import both Driver Card and Vehicle Unit data.<br>
                    To disconnect, remove your username and password and press Save.
                </td>
                <td>N/A</td>
                <td>0</td>
                <td>
                    <table>
                        <tr>
                            <td><label for="volvo_id_name">Username</label></td>
                            <td><input class="volvo_id" type="text" name="volvo_id_name" value="${data.volvo_id_name}" ${this.autocompleteOpts()}></td>
                        </tr>
                        <tr>
                            <td><label for="volvo_id_pass">Password</label></td>
                            <td><input class="volvo_id" type="password" name="volvo_id_pass" value="${data.volvo_id_pass}" ${this.autocompleteOpts()}></td>
                        </tr>
                    </table>
                    <a class="disconnect-api" data-integration-type="2">Disconnect</a>
                </td>
            </tr>
        `);
    }

    samsaraOptions(data) {
        let url = `${app.CACHE.URL_ADMIN}samsara/revoke_authorization`;
        if(app.CACHE.USER.user_type_id === app.CACHE.USER_TYPES.CHECKPRO) {
            url += `/${app.URI[2]}`;
        }

        app.DOM.content.find('.integration-table').append(`
            <tr data-integration-type="3">
                <td>Samsara</td>
                <td>
                    If you have already connected AssetGo and Samsara, you can disconnect at any time.<br>
                    <br>
                    We will attempt to collect data every day at 8:00am.<br>
                    <br>
                    To prevent overloading, attempts to reconnect to Samsara will be blocked until after 8:00am the next day.
                </td>
                <td>N/A</td>
                <td>0</td>
                <td>
                    <a href="${url}">Disconnect</a>
                </td>
            </tr>
        `);
    }

    geotabOptions(data) {
        app.DOM.content.find('.integration-table').append(`
            <tr data-integration-type="2">
                <td>Geotab</td>
                <td>
                Enter your username and password here to allow AssetGo to pull Tachograph data from your Geotab
                account.
                </td>
                <td>N/A</td>
                <td>0</td>
                <td>
                    <table>
                        <tr>
                            <td><label for="geotab_username">Username</label></td>
                            <td><input class="geo_id" type="text" id="geotab_username" name="geotab_username" value="${data.geotab_username}" ${autocompleteOpts()}></td>
                        </tr>
                        <tr>
                            <td><label for="geotab_password">Password</label></td>
                            <td><input class="geo_id" type="password" id="geotab_password" name="geotab_password" value="${data.geotab_password}" ${autocompleteOpts()}></td>
                        </tr>
                        <tr>
                            <td><label for="geotab_database">Database</label></td>
                            <td><input class="geo_id" type="text" id="geotab_database" name="geotab_database" value="${data.geotab_database}" ${autocompleteOpts()}></td>
                        </tr>
                        <tr>
                            <td><label for="geotab_hostname">Hostname</label></td>
                            <td><input class="geo_id" type="text" id="geotab_hostname" name="geotab_hostname" value="${data.geotab_hostname}" placeholder="Usually my.geotab.com" ${autocompleteOpts()}></td>
                        </tr>
                    </table>
                    <a class="disconnect-api" data-integration-type="4">Disconnect</a>
                </td>
            </tr>
        `)
    }
}